// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ceramics-index-js": () => import("./../../../src/pages/ceramics/index.js" /* webpackChunkName: "component---src-pages-ceramics-index-js" */),
  "component---src-pages-ceramics-process-js": () => import("./../../../src/pages/ceramics/process.js" /* webpackChunkName: "component---src-pages-ceramics-process-js" */),
  "component---src-pages-design-coffee-js": () => import("./../../../src/pages/design/coffee.js" /* webpackChunkName: "component---src-pages-design-coffee-js" */),
  "component---src-pages-design-dongzhu-pottery-js": () => import("./../../../src/pages/design/dongzhu-pottery.js" /* webpackChunkName: "component---src-pages-design-dongzhu-pottery-js" */),
  "component---src-pages-design-film-festival-js": () => import("./../../../src/pages/design/film-festival.js" /* webpackChunkName: "component---src-pages-design-film-festival-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shop-cart-js": () => import("./../../../src/pages/shop/cart.js" /* webpackChunkName: "component---src-pages-shop-cart-js" */),
  "component---src-pages-shop-index-js": () => import("./../../../src/pages/shop/index.js" /* webpackChunkName: "component---src-pages-shop-index-js" */),
  "component---src-pages-shop-product-js": () => import("./../../../src/pages/shop/product.js" /* webpackChunkName: "component---src-pages-shop-product-js" */),
  "component---src-templates-markdown-page-js": () => import("./../../../src/templates/markdown-page.js" /* webpackChunkName: "component---src-templates-markdown-page-js" */),
  "component---src-templates-photography-series-js": () => import("./../../../src/templates/photography-series.js" /* webpackChunkName: "component---src-templates-photography-series-js" */)
}

